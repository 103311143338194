import { PageLoading } from '@ant-design/pro-layout';
import {
  Button,
  Col,
  Collapse,
  Form,
  FormInstance,
  Input,
  Modal,
  Row,
  Select,
} from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import moment from 'moment';
import {
  FocusEvent,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ContextApp } from '../../contexts/ContextApp';
import { DetailItemType, EnumsValues } from '../../enums/EnumsValues';
import { CustomMessage } from '../../hooks';
import { IPage, IWord } from '../../interfaces/Extraction';
import {
  ICurrencyType,
  IFinishReviewResponse,
  IReceiptDetail,
  IReceiptHeader,
  IReceiptLetter,
  IReceiptType,
  ReceiptHC,
} from '../../interfaces/Receipt';
import { ITaxType } from '../../interfaces/Tenant';
import GraphqlService from '../../services/graphql/GraphqlService';
import './ReviewReceipt.less';
import ReviewReceiptCanvas from './ReviewReceiptCanvas';
import ReviewReceiptDetail from './ReviewReceiptDetail';
import { useHistory } from 'react-router-dom';
import { ILog } from '../../interfaces/Log';
import { notificationContext } from '../../contexts/NotificationContext';
import validations from './validations/validations';
import ReviewReceiptTaxes from './ReviewReceiptTaxes';
import ReviewReceiptDiscount from './ReviewReceiptDiscount';
import { ReceiptConfigurationByReceipt } from '../../interfaces/ReceiptConfigurationByReceipt';
import { ReceiptConfiguration } from '../../interfaces/ReceiptConfiguration';
import ReviewReceiptExitModal from './ReviewReceiptExitModal';
import { formatValueOnLoad } from '../../shared/formatValueOnLoad';
import { formatValueOnSave } from '../../shared/formatValueOnSave';
import { tourContext } from '../../contexts/TourContext';
import { IReceiptConceptExtracted } from '../../interfaces/ReceiptConceptExtracted';
import { IConceptMaster } from '../../interfaces/Concept';
import { IDocumentTypeInformationForTenant } from '../../interfaces/DocumentType';
import { Tools } from '../../shared';
import {
  ReviewerConfiguration,
  ReviewerConfigurationTypeOption,
} from '../../interfaces/ReviewerConfiguration';
import useDataValidationService from '../../hooks/useDataValidationService';
import { LoadingOutlined } from '@ant-design/icons';

interface arrayJson {
  json_element: IWord[];
  focused_element_id: string;
  pageNumber: number | null;
  shift: boolean;
}

const CUSTOMER_PREFIX = 'customer_'; // TODO: el prefijo se borraría con el uso de data_index en un futuro en el revisor dinámico.

export const ReviewReceipt = (props: any) => {
  const [receiptId, setReceiptId] = useState<number>();
  const { Query, Mutation, customRequest } = GraphqlService();
  const [receiptHeader, setReceiptHeader] = useState<IReceiptHeader>();
  const [pages, setPages] = useState<IPage[]>([]);
  const [loading, setLoading] = useState(true);
  const [receiptDetail, setReceiptDetail] = useState<IReceiptDetail[]>();
  const [taxTypesArray, setTaxTypesArray] = useState<ITaxType[]>();
  const [reviewApproved, setReviewApproved] = useState<boolean>(false);
  const [currencyTypesArray, setCurrencyTypesArray] =
    useState<ICurrencyType[]>();
  const [receiptTypesArray, setReceiptTypesArray] = useState<IReceiptType[]>();
  const [receiptLetterArray, setReceiptLetterArray] =
    useState<IReceiptLetter[]>();
  const receiptLetterFilterArray = useRef<IReceiptLetter[]>();
  const taxTypesFilterArray = useRef<ITaxType[]>();
  const [pdfBase64, setPdfBase64] = useState<string>('');
  const { getErrorMessage } = CustomMessage();
  const focusedInput = useRef<string>();
  const lastFocusedInput = useRef<string>();
  const history = useHistory();
  const renderNumberRef = useRef<number>(0);
  const [lastLog, setLastLog] = useState<ILog>();
  let concatenatedContentRef = useRef<string>('');
  const [form] = Form.useForm();
  const { t, isNumeric, seenToursRef } = useContext(ContextApp);
  const { openNotification } = useContext(notificationContext);
  const receiptHeaderConfig = useRef<ReceiptHC>();
  const senderConfig = useRef<ReceiptHC>();
  const customerConfig = useRef<ReceiptHC>();
  const [receiptDetailConfig, setReceiptDetailConfig] = useState<ReceiptHC>();
  const [conceptConfigTax, setConceptConfigTax] = useState<ReceiptHC>();
  const [conceptConfigDiscount, setConceptConfigDiscount] =
    useState<ReceiptHC>();

  const [subtotalTax, setSubtotalTax] = useState<number>(0);
  const [subtotalDiscount, setSubtotalDiscount] = useState<number>(0);
  const [subtotalItemDetail, setSubtotalItemDetail] = useState<number>(0);
  const totalReceipt = subtotalTax + subtotalDiscount + subtotalItemDetail;
  const [tenantId, setTenantId] = useState<number>();
  const headerJsonElementArrayRef = useRef<arrayJson>({
    json_element: [],
    focused_element_id: '',
    pageNumber: null,
    shift: false,
  });
  const [typeOfItemSelected, setTypeOfItemSelected] =
    useState<'product' | 'discount'>('product');
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [isTourModalOpen, setIsTourModalOpen] = useState(false);
  const [expandedGeneralData, setExpandedGeneralData] =
    useState<boolean>(false);
  const [expandedDetail, setExpandedDetail] = useState<boolean>(false);
  const [expandedLogs, setExpandedLogs] = useState<boolean>(false);
  const { createTour, createSeenTour } = useContext(tourContext);
  const isFormChanged = useRef<boolean>(false);
  const [taxDetails, setTaxDetails] = useState<IReceiptConceptExtracted[]>();
  const [discountDetails, setDiscountDetails] =
    useState<IReceiptConceptExtracted[]>();
  const [conceptMasters, setConceptMasters] = useState<IConceptMaster[]>([]);
  const [loadingFinishReview, setLoadingFinishReview] =
    useState<boolean>(false);

  const lastReceiptTypeSelected = useRef<number>();
  const discardingReceipt = useRef<boolean>(false);
  const [reviewerConfigurationOptions, setReviewerConfigurationOptions] =
    useState<ReviewerConfigurationTypeOption[] | null>(null);
  const [reviewerConfiguration, setReviewerConfiguration] =
    useState<ReviewerConfiguration | null>(null);

  useEffect(() => {
    if (seenToursRef.current.includes(EnumsValues.TourSections.ReceiptReview))
      return;
    setIsTourModalOpen(true);
  }, []);

  useEffect(() => {
    if (receiptHeader)
      lastReceiptTypeSelected.current = receiptHeader.receipt_type_id;
    form.setFieldsValue({
      ...receiptHeader,
      receipt_total:
        receiptHeader?.receipt_total != null &&
        isNumeric(receiptHeader.receipt_total)
          ? formatValueOnLoad(receiptHeader.receipt_total)
          : null,
      receipt_tax_total:
        receiptHeader?.receipt_tax_total != null &&
        isNumeric(receiptHeader.receipt_tax_total)
          ? formatValueOnLoad(receiptHeader.receipt_tax_total)
          : null,
      receipt_discount_total:
        receiptHeader?.receipt_discount_total != null &&
        isNumeric(receiptHeader.receipt_discount_total)
          ? formatValueOnLoad(receiptHeader.receipt_discount_total)
          : null,
      receipt_subtotal:
        receiptHeader?.receipt_subtotal != null &&
        isNumeric(receiptHeader.receipt_subtotal)
          ? formatValueOnLoad(receiptHeader.receipt_subtotal)
          : null,
      tax_number: receiptHeader?.sender?.tax_number,
      tax_condition: receiptHeader?.sender?.tax_condition,
      name: receiptHeader?.sender?.name,
      tax_type_id: receiptHeader?.sender?.tax_type_id,
      customer_tax_number: receiptHeader?.customer?.tax_number,
      customer_tax_type_id: receiptHeader?.customer?.tax_type_id,
      customer_name: receiptHeader?.customer?.name,
      receipt_date: receiptHeader?.receipt_date
        ? moment(receiptHeader?.receipt_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : undefined,
      receipt_verification_due_date:
        receiptHeader?.receipt_verification_due_date
          ? moment(
              receiptHeader?.receipt_verification_due_date,
              'YYYY-MM-DD',
            ).format('DD-MM-YYYY')
          : undefined,
      due_date: receiptHeader?.due_date
        ? moment(receiptHeader?.due_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : undefined,
      receipt_currency_id: receiptHeader?.receipt_currency_id,
      exchange_rate_quote_value: receiptHeader?.exchange_rate_quote_value,
      exchange_rate_quote_description:
        receiptHeader?.exchange_rate_quote_description,
      exchange_rate_quote_currency: receiptHeader?.exchange_rate_quote_currency,
    });
  }, [receiptHeader]);

  const isConfigAlwaysReview = useMemo(() => {
    if (!reviewerConfigurationOptions || !reviewerConfiguration) {
      return false;
    }

    let currentReviewerConfigurationOptionId: number;
    const [tenantConfiguration] =
      reviewerConfiguration.reviewer_configuration_type_tenant;

    if (tenantConfiguration) {
      currentReviewerConfigurationOptionId =
        tenantConfiguration.reviewer_configuration_type_option_id;
    } else {
      currentReviewerConfigurationOptionId =
        reviewerConfiguration.reviewer_configuration_type_option_id;
    }

    const configurationCode = reviewerConfigurationOptions.find(
      (option: ReviewerConfigurationTypeOption) =>
        option.id === currentReviewerConfigurationOptionId,
    )?.code;

    return (
      configurationCode ===
      EnumsValues.ReviewerConfigurationOptionCodes.REVIEW_ALWAYS
    );
  }, [reviewerConfigurationOptions, reviewerConfiguration]);

  useLayoutEffect(() => {
    renderNumberRef.current++;
    if (props.match.params.receipt_id) {
      setReceiptId(Number(props.match.params.receipt_id));
    }

    obtainInitialCalls(
      parseInt(props.match.params.receipt_id),
      renderNumberRef.current,
    );
  }, []);

  const {
    getReviewerConfigurationTypeByDocumentTypeId,
    getReviewerConfigurationTypeOptions,
  } = useDataValidationService();

  const obtainInitialCalls = async (
    receiptId: number,
    renderNumber: number,
  ) => {
    await Promise.all([
      getReceiptHeader(receiptId, renderNumber),
      getlastLogByValidatingReceipt(receiptId),
      getExtractionLog(receiptId, renderNumber),
    ]);

    setLoading(false);
  };

  const showInput = useCallback(
    (inputName: string) => {

      const fieldRH = receiptHeaderConfig.current?.fields.find(
        (field: any) => field.name === inputName,
      );
      if (fieldRH) {
        return fieldRH.hidden;
      }
      const fieldS = senderConfig.current?.fields.find(
        (field: any) => field.name === inputName,
      );
      if (fieldS) {
        return fieldS.hidden;
      }
      const fieldC = customerConfig.current?.fields.find(
        (field: any) => field.name === inputName,
      );
      if (fieldC) {
        return fieldC.hidden;
      }
      return true;
    },
    [receiptHeaderConfig, senderConfig, customerConfig],
  );

  const requiredInput = useCallback(
    (inputName: string) => {
      const fieldRH = receiptHeaderConfig.current?.fields.find(
        (field: any) => field.name === inputName,
      );
      if (fieldRH) {
        return fieldRH.classification_id !== 2;
      }
      const fieldS = senderConfig.current?.fields.find(
        (field: any) => field.name === inputName,
      );
      if (fieldS) {
        return fieldS.classification_id !== 2;
      }
      const fieldC = customerConfig.current?.fields.find(
       (field: any) => field.name === inputName,
      );
      if (fieldC) {
        return fieldC.classification_id !== 2;
      }
    },
    [receiptHeaderConfig, senderConfig, customerConfig],
  );

  const getTaxTypes = async (tenant_id: number) => {
    const data: ITaxType[] = await customRequest({
      query: Query.taxTypes,
      variables: {
        filter: {
          tenant_id: tenant_id,
        },
      },
    });
    taxTypesFilterArray.current = data;
    await setTaxTypesArray(data);
  };

  const goBack = async () => {
    await deleteManyReviewsReceiptHeader();
    history.push({
      pathname: '/app/receipt',
      state: {
        keepFilter: true,
      },
    });
  };

  const setReceiptStatusDiscarded = async (
    receiptHeaderId: number,
    receiptFilename: string,
    newReceiptTypeId: number,
  ) => {
    try {
      await customRequest({
        mutation: Mutation.setReceiptHeaderStatusDiscarded,
        variables: {
          input: {
            receipt_header_id: receiptHeaderId,
            receiptDataToOverride: {
              receipt_type_id: newReceiptTypeId,
              receipt_letter_id: null,
            },
          },
        },
      });
      openNotification({
        msj: t('message.receiptDiscarded'),
        filename: receiptFilename,
        type: 'success',
      });
      goBack();
    } catch (error: any) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const handleUpdateReceiptType = async (type: number) => {
    if (!tenantId) return;

    const oldReceiptTypeId = lastReceiptTypeSelected.current;

    lastReceiptTypeSelected.current = type;

    const newReceiptType = (receiptTypesArray || []).find(
      (item) => item.id === type,
    );

    if (!newReceiptType || !receiptHeader) return;

    const documentTypeInformation: IDocumentTypeInformationForTenant =
      await customRequest({
        query: Query.getDocumentTypeConfigurationForTenant,
        variables: {
          input: {
            receipt_type_id: type,
            tenant_id: tenantId,
          },
        },
      });

    const reviewerConfiguration =
      await getReviewerConfigurationTypeByDocumentTypeId({
        document_type_id: documentTypeInformation?.documentTypeId,
        tenant_id: tenantId,
      });

    setReviewerConfiguration(reviewerConfiguration);

    if (documentTypeInformation.automatically_discarded) {
      /* Esperar confirmacion */
      discardingReceipt.current = true;
      const discardResult = await new Promise<boolean>((resolve) => {
        Modal.confirm({
          content: (
            <>
              <div>
                <p className="modal-discard-title">
                  {t('action.discardReceipt')}
                </p>
                <p>
                  {t(
                    'message.documentTypeIsAutomaticallyDiscardedForYourOrganization',
                    {
                      documentTypeName: newReceiptType.name,
                    },
                  )}
                </p>
                <p>
                  {t('message.confirmSetReceiptStatusDiscarded', {
                    receiptNumber:
                      receiptHeader.receipt_number ||
                      receiptHeader.filename ||
                      receiptId ||
                      '',
                  })}
                </p>
              </div>
            </>
          ),
          icon: (
            <span
              className="material-symbols-outlined receipt-exclamation-icon"
              translate="no"
            >
              error
            </span>
          ),
          width: '448px',
          onOk: () => {
            resolve(true);
          },
          onCancel: () => {
            resolve(false);
          },
          okText: t('action.discarded'),
          okButtonProps: {
            className: 'save-btn',
          },
        });
      });

      if (!discardResult) {
        lastReceiptTypeSelected.current = oldReceiptTypeId;
        form.setFieldValue('receipt_type_id', oldReceiptTypeId);
        discardingReceipt.current = false;
      } else {
        setReceiptStatusDiscarded(
          receiptHeader.id,
          receiptHeader.filename,
          type,
        );
        return;
      }
    }

    const data: ReceiptConfiguration[] = await customRequest({
      query: Query.receiptConfigurations,
      variables: {
        filter: {
          receipt_type_id: type,
        },
        orderBy: {
          field: 'id',
          direction: 'asc',
        },
      },
    });

    receiptHeaderConfig.current = data[0].json_receipt_header;
    senderConfig.current = data[0].json_sender;
    customerConfig.current = data[0].json_customer;
    customerConfig.current.fields.forEach(f => f.name = CUSTOMER_PREFIX+f.name); 
    setReceiptDetailConfig(data[0].json_receipt_detail_product);
    setConceptConfigTax(data[0].json_concept_tax);
    setConceptConfigDiscount(data[0].json_concept_discount);
    await getReceiptLetters(tenantId, type);
  };

  const deleteManyReviewsReceiptHeader = async () => {
    await customRequest({
      mutation: Mutation.deleteManyReviewReceiptHeaderField,
      variables: {
        input: {
          receipt_header_id: receiptId,
        },
      },
    });
  };

  const UpdateFormByReceipt = async (receipt_id: number) => {
    const data: ReceiptConfigurationByReceipt = await customRequest({
      query: Query.receiptConfigurationByReceipt,
      variables: {
        receipt_header_id: receipt_id,
      },
    });
    receiptHeaderConfig.current = data.json_receipt_header;
    senderConfig.current = data.json_sender;
    customerConfig.current = data.json_customer;
    customerConfig.current.fields.forEach(f => f.name = CUSTOMER_PREFIX+f.name);
    setReceiptDetailConfig(data.json_receipt_detail_product);
    setConceptConfigTax(data.json_concept_tax);
    setConceptConfigDiscount(data.json_concept_discount);
  };

  const UpdateFormByReceiptType = async (
    currentReceptTypeId: number,
    currentTenantId: number,
  ) => {
    const data: ReceiptConfigurationByReceipt = await customRequest({
      query: Query.receiptConfigurationByReceiptType,
      variables: {
        receipt_type_id: currentReceptTypeId,
        tenant_id: currentTenantId,
      },
    });

    receiptHeaderConfig.current = data.json_receipt_header;
    senderConfig.current = data.json_sender;
    customerConfig.current = data.json_customer;
    customerConfig.current.fields.forEach(f => f.name = CUSTOMER_PREFIX+f.name);
    setReceiptDetailConfig(data.json_receipt_detail_product);
    setConceptConfigTax(data.json_concept_tax);
    setConceptConfigDiscount(data.json_concept_discount);
  };

  const updateEditItemForm = useCallback(
    async (selectedValue: string, formInstance?: FormInstance) => {
      const data: ReceiptConfigurationByReceipt = await customRequest({
        query: Query.receiptConfigurationByReceiptType,
        variables: {
          receipt_type_id: form.getFieldValue('receipt_type_id'),
          tenant_id: form.getFieldValue('tenant_id'),
        },
      });
      receiptHeaderConfig.current = data.json_receipt_header;
      senderConfig.current = data.json_sender;
      let currentValues = formInstance?.getFieldsValue();
      if (Number(selectedValue) === DetailItemType.product) {
        setTypeOfItemSelected('product');
        setReceiptDetailConfig(data.json_receipt_detail_product);
        formInstance?.setFieldsValue({
          receipt_line_tax_total: positiveSign(
            currentValues.receipt_line_tax_total,
          ),
          subtotal: positiveSign(currentValues.subtotal),
          unit_price: positiveSign(currentValues.unit_price),
          subtotal_without_tax: positiveSign(
            currentValues.subtotal_without_tax,
          ),
        });
      } else {
        setTypeOfItemSelected('discount');
        setReceiptDetailConfig(data.json_receipt_detail_discount);
        formInstance?.setFieldsValue({
          receipt_line_tax_total: negativeSign(
            currentValues.receipt_line_tax_total,
          ),
          subtotal: negativeSign(currentValues.subtotal),
          unit_price: negativeSign(currentValues.unit_price),
          subtotal_without_tax: negativeSign(
            currentValues.subtotal_without_tax,
          ),
        });
      }
    },
    [receiptId],
  );

  const positiveSign = (value?: string) => {
    if (!value) return null;
    return value.includes('-') ? value.replace('-', '') : value;
  };

  const negativeSign = (value?: string) => {
    if (!value) return null;
    return !value.includes('-') ? '-' + value : value;
  };

  const getCurrencyTypes = async (tenant_id: number) => {
    const data: ICurrencyType[] = await customRequest({
      query: Query.currencyTypes,
      variables: {
        filter: {
          tenant_id: tenant_id,
        },
      },
    });

    await setCurrencyTypesArray(data);
  };

  const getReceiptPdf = async (receipt_id: number) => {
    const data = await customRequest({
      query: Query.getReceiptHeaderPdf,
      variables: {
        id: Number(receipt_id),
      },
    });

    setPdfBase64(data?.file);
  };

  const getReceiptTypes = async (tenant_id: number) => {
    const data: IReceiptType[] = await customRequest({
      query: Query.receipTypes,
      variables: {
        filter: {
          tenant_id: tenant_id,
          selectable: true,
          active: true,
        },
      },
    });

    await setReceiptTypesArray(data);
  };

  const getReceiptLetters = async (
    tenant_id: number,
    receipt_type_id: number,
  ) => {
    const data: IReceiptLetter[] = await customRequest({
      query: Query.receiptLetters,
      variables: {
        filter: {
          tenant_id: tenant_id,
          receipt_type_id: receipt_type_id,
        },
      },
    });

    receiptLetterFilterArray.current = data;
    setReceiptLetterArray(data);
    const foundElement = data.find(
      (element) => element.id === form.getFieldValue('receipt_letter_id'),
    );

    if (foundElement !== undefined) {
      form.setFieldsValue({
        receipt_letter_id: foundElement.id,
      });
    } else {
      form.setFieldsValue({
        receipt_letter_id: undefined,
      });
    }
  };

  const getReceiptConceptExtracteds = async (
    tenant_id: number,
    receipt_header_id: number,
  ) => {
    try {
      const data: IReceiptConceptExtracted[] = await customRequest({
        query: Query.receiptConceptExtracteds,
        variables: {
          filter: {
            tenant_id: tenant_id,
            receipt_header_id: receipt_header_id,
          },
        },
      });

      if (data) {
        setTaxDetails(
          data
            .filter(
              (ce) => ce.concept_type_id === EnumsValues.ConceptType.impuesto,
            )
            .map((ele) => ({ ...ele, __typename: undefined })),
        );
        setDiscountDetails(
          data
            .filter(
              (ce) => ce.concept_type_id === EnumsValues.ConceptType.descuento,
            )
            .map((ele) => ({ ...ele, __typename: undefined })),
        );
      }
    } catch (error) {
      //Intentionally left blank
    }
  };

  const getConceptMasters = async (tenant_id: number) => {
    if (tenant_id) {
      try {
        const data: IConceptMaster[] = await customRequest({
          query: Query.conceptMasters,
          variables: {
            filter: {
              tenant_id: tenant_id,
            },
          },
        });

        setConceptMasters(data);
      } catch (error) {
        openNotification({
          msj: getErrorMessage(error),
          type: 'error',
        });
      }
    }
  };

  const getReceiptHeader = async (
    receipt_id: number,
    renderNumber: number,
    get_details_only?: boolean,
  ) => {
    try {
      const data: IReceiptHeader = await customRequest({
        query: Query.receiptHeader,
        variables: {
          id: Number(receipt_id),
        },
      });

      if (renderNumber !== renderNumberRef.current) return;
      if (
        data.status_id !== EnumsValues.ReceiptStatus.ToReview &&
        reviewApproved
      ) {
        setIsExitModalOpen(true);
      } else if (data.status_id !== EnumsValues.ReceiptStatus.ToReview) {
        openNotification({
          msj: t('ReviewReceipt.messageError.receiptReviewError'),
          type: 'warning',
        });
        history.push({
          pathname: '/app/receipt',
        });
      }
      setReviewApproved(true);

      if (!get_details_only) {
        setReceiptHeader(data);
      }

      const sortedReceiptDetail = data.receipt_detail.sort(
        (a, b) => Number(a.receipt_line_number) - Number(b.receipt_line_number),
      );

      const sortedReceiptDetailWithIndex = sortedReceiptDetail.map((item) => {
        return {
          ...item,
          key: item.id,
        };
      });

      setReceiptDetail(sortedReceiptDetailWithIndex);
      setTenantId(data.tenant_id);
      await Promise.all([
        form.getFieldValue('receipt_type_id')
          ? UpdateFormByReceiptType(
              form.getFieldValue('receipt_type_id'),
              data.tenant_id,
            )
          : UpdateFormByReceipt(data.id),
        getConceptMasters(data.tenant_id),
        getTaxTypes(data.tenant_id),
        getCurrencyTypes(data.tenant_id),
        getReceiptTypes(data.tenant_id),
        getReceiptLetters(data.tenant_id, data.receipt_type_id),
        getReceiptPdf(Number(receipt_id)),
        getReceiptConceptExtracteds(data.tenant_id, Number(receipt_id)),
      ]);

      const documentType = await customRequest({
        query: Query.getDocumentTypeConfigurationForTenant,
        variables: {
          input: {
            receipt_type_id: data.receipt_type_id,
            tenant_id: data.tenant_id,
          },
        },
      });

      const reviewerConfiguration =
        await getReviewerConfigurationTypeByDocumentTypeId({
          document_type_id: documentType.documentTypeId,
          tenant_id: data.tenant_id,
        });

      const reviewerConfigurationOptions =
        await getReviewerConfigurationTypeOptions({
          tenant_id: data.tenant_id,
        });

      setReviewerConfiguration(reviewerConfiguration);
      setReviewerConfigurationOptions(reviewerConfigurationOptions);
    } catch (error) {
      //intentional
    }
  };
  const getExtractionLog = async (receipt_id: number, renderNumber: number) => {
    try {
      const data = await customRequest({
        query: Query.extractionLog,
        variables: {
          receipt_header_id: receipt_id,
        },
      });
      if (renderNumber !== renderNumberRef.current) return;
      setPages(data.json_response);
    } catch (error) {
      //intentional
    }
  };

  const getlastLogByValidatingReceipt = async (receipt_id: number) => {
    try {
      const data: ILog = await customRequest({
        query: Query.lastLogByValidatingReceipt,
        variables: {
          receipt_header_id: receipt_id,
        },
      });

      setLastLog(data);
    } catch (error) {
      //intentional
    }
  };

  const actualizarReceiptheader = async (values: any) => {
    const { validateOnly } = values;
    if (!validateOnly) {
      setLoadingFinishReview(true);

      if (
        lastLog &&
        lastLog.description &&
        lastLog.description.length > 0 &&
        isConfigAlwaysReview
      ) {
        const confirmationCheck = await new Promise<boolean>((resolve) => {
          Modal.confirm({
            content: (
              <>
                <div>
                  <p className="modal-confirmation-check-title">
                    {t('ReviewReceipt.confirmationCheckModal.title')}
                  </p>
                  <p className="modal-confirmation-check-paragraph">
                    {t('ReviewReceipt.confirmationCheckModal.paragraph')}
                  </p>
                </div>
              </>
            ),
            icon: (
              <span
                className="material-symbols-outlined receipt-exclamation-icon"
                translate="no"
              >
                error
              </span>
            ),
            width: '448px',
            onOk: () => {
              resolve(true);
            },
            onCancel: () => {
              resolve(false);
            },
            okText: Tools.capitalize(t('action.finish')),
            okButtonProps: {
              type: 'primary',
            },
          });
        });

        if (!confirmationCheck) {
          setLoadingFinishReview(false);
          return false;
        }
      }
    }
    const dataToSendToServerRH: Record<string, any> = {};
    const dataToSendToServerSender: Record<string, any> = {};
    const dataToSendToServerCustomer: Record<string, any> = {};
    dataToSendToServerRH['concepts'] = [];
    dataToSendToServerRH['details'] = [];

    if (
      headerJsonElementArrayRef.current.json_element.length > 0 &&
      headerJsonElementArrayRef.current.shift
    ) {
      await customRequest({
        mutation: Mutation.upsertReviewReceiptHeaderField,
        variables: {
          input: {
            receipt_header_id: Number(props.match.params.receipt_id),
            field: headerJsonElementArrayRef.current.focused_element_id.replace(CUSTOMER_PREFIX, ''),
            value: concatenatedContentRef.current,
            json_value: headerJsonElementArrayRef.current.json_element,
            page_number: headerJsonElementArrayRef.current.pageNumber,
          },
        },
      });
    }

    if (receiptHeaderConfig.current?.fields) {
      for (const fieldConfig of receiptHeaderConfig.current.fields) {
        dataToSendToServerRH['id'] = receiptHeader?.id;
        dataToSendToServerRH['tenant_id'] = receiptHeader?.tenant_id;
        if (fieldConfig.hidden) {
          continue;
        }

        const value = getValueFromFormObject(values, fieldConfig);
        dataToSendToServerRH[fieldConfig.name] = value;
      }
    }

    if (senderConfig.current?.fields) {
      for (const fieldConfig of senderConfig.current.fields) {
        if (fieldConfig.hidden) {
          continue;
        }

        const value = getValueFromFormObject(values, fieldConfig);

        dataToSendToServerSender[fieldConfig.name] = value;
      }
      dataToSendToServerRH['sender'] = dataToSendToServerSender;
    }

    if (customerConfig.current?.fields) {
      for (const fieldConfig of customerConfig.current.fields) {
        if (fieldConfig.hidden) {
          continue;
        }
        const value = getValueFromFormObject(values, fieldConfig);
        dataToSendToServerCustomer[fieldConfig.name.replace(CUSTOMER_PREFIX, '')] = value;
      }
      dataToSendToServerRH['customer'] = dataToSendToServerCustomer;
    }

    if (receiptDetail && receiptDetail.length) {
      dataToSendToServerRH['details'] = receiptDetail.map((ele) => ({
        ...ele,
        id: ele.id > 0 ? ele.id : undefined,
        key: undefined,
        receipt_detail_item_type: undefined,
        receipt_tax_type: undefined,
      }));
    }

    if (taxDetails && taxDetails.length) {
      if (dataToSendToServerRH['concepts']) {
        dataToSendToServerRH['concepts'] = [
          ...dataToSendToServerRH['concepts'],
          ...taxDetails.map((ele: any) => {
            return {
              ...ele,
              id: ele.id > 0 ? ele.id : undefined,
              tenant_id: receiptHeader?.tenant_id,
              receipt_tax_type: undefined,
              concept_master_id: undefined,
              concept_master: ele.concept_master
                ? {
                    ...ele.concept_master,
                    tenant_id: receiptHeader?.tenant_id,
                    id: undefined,
                    its: undefined,
                    dts: undefined,
                    uts: undefined,
                    concept_rule: undefined,
                    concept_type: undefined,
                    logical_operation_type: undefined,
                    __typename: undefined,
                  }
                : undefined,
            };
          }),
        ];
      } else {
        dataToSendToServerRH['concepts'] = taxDetails.map((ele: any) => {
          return {
            ...ele,
            id: ele.id > 0 ? ele.id : undefined,
            tenant_id: receiptHeader?.tenant_id,
            receipt_tax_type: undefined,
            concept_master_id: undefined,
            concept_master: ele.concept_master
              ? {
                  ...ele.concept_master,
                  tenant_id: receiptHeader?.tenant_id,
                  id: undefined,
                  its: undefined,
                  dts: undefined,
                  uts: undefined,
                  concept_rule: undefined,
                  concept_type: undefined,
                  logical_operation_type: undefined,
                  __typename: undefined,
                }
              : undefined,
          };
        });
      }
    }

    if (discountDetails && discountDetails.length) {
      if (dataToSendToServerRH['concepts']) {
        dataToSendToServerRH['concepts'] = [
          ...dataToSendToServerRH['concepts'],
          ...discountDetails.map((ele: any) => {
            return {
              ...ele,
              id: ele.id > 0 ? ele.id : undefined,
              tenant_id: receiptHeader?.tenant_id,
              receipt_tax_type: undefined,
              concept_master_id: undefined,
              concept_master: ele.concept_master
                ? {
                    ...ele.concept_master,
                    tenant_id: receiptHeader?.tenant_id,
                    id: undefined,
                    its: undefined,
                    dts: undefined,
                    uts: undefined,
                    concept_rule: undefined,
                    concept_type: undefined,
                    logical_operation_type: undefined,
                    __typename: undefined,
                  }
                : undefined,
            };
          }),
        ];
      } else {
        dataToSendToServerRH['concepts'] = discountDetails.map((ele: any) => {
          return {
            ...ele,
            id: ele.id > 0 ? ele.id : undefined,
            tenant_id: receiptHeader?.tenant_id,
            receipt_tax_type: undefined,
            concept_master_id: undefined,
            concept_master: ele.concept_master
              ? {
                  ...ele.concept_master,
                  tenant_id: receiptHeader?.tenant_id,
                  id: undefined,
                  its: undefined,
                  dts: undefined,
                  uts: undefined,
                  concept_rule: undefined,
                  concept_type: undefined,
                  logical_operation_type: undefined,
                  __typename: undefined,
                }
              : undefined,
          };
        });
      }
    }

    if (dataToSendToServerRH['receipt_period'] === null) {
      dataToSendToServerRH['receipt_period'] = undefined;
    }

    try {
      const response: IFinishReviewResponse = await customRequest({
        mutation: Mutation.finishReview,
        variables: {
          input: {
            ...dataToSendToServerRH,
            validateByConfiguration: validateOnly ?? false,
          },
        },
      });
      isFormChanged.current = false;
      if (receiptId) getlastLogByValidatingReceipt(receiptId);
      if (response.hasValidationErrors === 'true') {
        if (response.logDescription && response.logDescription.length > 0) {
          openNotification({
            msj: t('ReviewReceipt.message.validationErrorsWereFound'),
            type: 'warning',
          });
          setExpandedLogs(true);
        } else {
          openNotification({
            msj: t('ReviewReceipt.receiptUpdated'),
            type: 'success',
          });
        }
      } else {
        openNotification({
          msj: t('ReviewReceipt.receiptUpdated'),
          type: 'success',
        });
        history.push({
          pathname: '/app/receipt',
          state: {
            keepFilter: true,
            updatedReceiptId: receiptId,
            receiptStatusID: receiptHeader?.status_id,
          },
        });
      }

      form.setFieldValue('validateOnly', false);
    } catch (error: any) {
      if (error.status_code) {
        if (
          error.status_code ===
          EnumsValues.ErrorsStatusCode.receiptNotToReviewCode
        ) {
          history.push({
            pathname: '/app/receipt',
            state: {
              keepFilter: true,
              updatedReceiptId: receiptId,
              receiptStatusID: receiptHeader?.status_id,
            },
          });
        } else {
          openNotification({
            msj: getErrorMessage(error),
            type: 'error',
          });
        }
      }
      form.setFieldValue('validateOnly', false);
    }

    setLoadingFinishReview(false);
  };

  function getValueFromFormObject(
    formObject: any,
    fieldConfig: {
      name: string;
      classification_id: number;
      type: string;
      hidden?: boolean | undefined;
      readonly?: boolean | undefined;
    },
  ) {
    const formField = formObject[fieldConfig.name];

    if (!formField && fieldConfig.classification_id === 1) {
      return null;
    } else if (fieldConfig.classification_id === 2 && !formField) {
      return null;
    }
    switch (fieldConfig.type) {
      case 'string':
        if (
          fieldConfig.name === 'receipt_date' ||
          fieldConfig.name === 'receipt_verification_due_date' ||
          fieldConfig.name === 'due_date'
        ) {
          return formField !== '' && formField !== undefined
            ? moment(formField, 'DD-MM-YYYY').format('YYYY-MM-DD')
            : null;
        }
        return String(formField);
      case 'number':
        if (
          fieldConfig.name === 'receipt_total' ||
          fieldConfig.name === 'receipt_tax_total' ||
          fieldConfig.name === 'receipt_discount_total' ||
          fieldConfig.name === 'receipt_subtotal'
        ) {
          return parseFloat(formatValueOnSave(String(formField)));
        }
        return Number(formField);
      default:
        return formField;
    }
  }

  const onCanvasItemClick = useCallback(
    async (
      content: string,
      shiftKey: boolean = false,
      element: IWord | null,
      pageNumber,
    ) => {
       
      if (focusedInput.current && element) {
        if (
          lastFocusedInput.current &&
          lastFocusedInput.current !== focusedInput.current
        ) {
          const activeElements = document.querySelectorAll(
            '.pdf-canvas-item-active',
          );
          activeElements.forEach((element) => {
            element.classList.remove('pdf-canvas-item-active'); //removemos los antiguos elementos tildados antes de presionar shift
          });
          shiftKey = false; // ignoramos shiftkey para no introducir contenido duplicado a otro input
          headerJsonElementArrayRef.current.shift = false;

          if (headerJsonElementArrayRef.current.json_element.length > 1) {
            await customRequest({
              mutation: Mutation.upsertReviewReceiptHeaderField,
              variables: {
                input: {
                  receipt_header_id: Number(props.match.params.receipt_id),
                  field: headerJsonElementArrayRef.current.focused_element_id.replace(CUSTOMER_PREFIX, ''), // eliminamos el prefijo que se agrega al campo del form (evita pisar a sender)
                  value: concatenatedContentRef.current,
                  json_value: headerJsonElementArrayRef.current.json_element,
                  page_number: pageNumber,
                },
              },
            });
          }
          headerJsonElementArrayRef.current.json_element = [];
        }

        const focusedElement = document.getElementById(focusedInput.current);
        if (!focusedElement) return;
        focusedElement.focus({ preventScroll: true });

        const findConfig = (
          configArray: ReceiptHC['fields'] | undefined,
          focusedElementId: string,
        ) => {
          return configArray?.find((item) => item.name === focusedElementId);
        };

        const inputConfig =
          findConfig(receiptHeaderConfig.current?.fields, focusedElement.id) ??
          findConfig(senderConfig.current?.fields, focusedElement.id) ??
          findConfig(customerConfig.current?.fields, focusedElement.id) ?? 
          undefined;

        
        if (!inputConfig) return;

        const inputConfigType = inputConfig?.validation_type ?? undefined;

        const receiptLetterIsNotValid =
          focusedElement.id === 'receipt_letter_id' &&
          !receiptLetterFilterArray.current?.find(
            (field: any) => field.name === content,
          );

        if (shiftKey) {
          if (receiptLetterIsNotValid) return;

          headerJsonElementArrayRef.current.shift = true;
          headerJsonElementArrayRef.current.json_element.push(element);
          headerJsonElementArrayRef.current.focused_element_id =
            focusedElement?.id;
          headerJsonElementArrayRef.current.pageNumber = pageNumber;
          if (concatenatedContentRef.current !== '') {
            concatenatedContentRef.current =
              concatenatedContentRef.current +
              (inputConfig?.separator ? inputConfig.separator : ' ') +
              content;
          } else {
            concatenatedContentRef.current = content;
          }

          concatenatedContentRef.current = validations({
            valueToValidate: concatenatedContentRef.current.replace(CUSTOMER_PREFIX, ''),
            type: inputConfigType,
          });

          checkSelectInputs({
            content: concatenatedContentRef.current,
            focusedElementId: focusedElement.id,
            focusedInputCurrent: focusedInput.current,
          });
        } else {
          headerJsonElementArrayRef.current.json_element = [];
          headerJsonElementArrayRef.current.json_element.push(element);
          headerJsonElementArrayRef.current.shift = false;
          concatenatedContentRef.current = content;
          if (content.indexOf('$') !== -1 || content.indexOf('usd$')) {
            content = content.replace('$', '');
            content = content.replace('usd$', '');
          }

          content = validations({
            valueToValidate: content.replace(CUSTOMER_PREFIX, ''),
            type: inputConfigType,
          });

          if (
            focusedElement.id === 'receipt_letter_id' &&
            !checkIfReceiptLetterIsValid(content)
          ) {
            return;
          }

          if (
            focusedElement.id.replace(CUSTOMER_PREFIX, '') === 'tax_type_id' &&
            !checkIfTaxTypeIsValid(content)
          ) {
            return;
          }


          if (content) {
            await customRequest({
              mutation: Mutation.upsertReviewReceiptHeaderField,
              variables: {
                input: {
                  receipt_header_id: Number(props.match.params.receipt_id),
                  field: focusedElement?.id.replace(CUSTOMER_PREFIX, ''),
                  value: content,
                  json_value: headerJsonElementArrayRef.current.json_element,
                  page_number: pageNumber,
                },
              },
            });
          }

          checkSelectInputs({
            content,
            focusedElementId: focusedElement.id,
            focusedInputCurrent: focusedInput.current,
          });
        }

        lastFocusedInput.current = focusedInput.current;

        form.validateFields([focusedInput.current]);
      }
    },
    [],
  );

  const checkSelectInputs = ({
    content,
    focusedElementId,
    focusedInputCurrent,
  }: {
    content: string;
    focusedElementId: string;
    focusedInputCurrent: string;
  }) => {
    switch (focusedElementId.replace(CUSTOMER_PREFIX, '')) {
      case 'receipt_letter_id':
        const letter = checkIfReceiptLetterIsValid(content);
        form.setFieldValue(focusedInputCurrent, letter?.id);
        break;
      case 'tax_type_id':
        const tax_type = checkIfTaxTypeIsValid(content);
        form.setFieldValue(focusedInputCurrent, tax_type?.id);
        break;
      default:
        form.setFieldValue(focusedInputCurrent, content || null);
    }
    isFormChanged.current = true;
  };

  const checkIfReceiptLetterIsValid = (content: string) => {
    return receiptLetterFilterArray.current?.find(
      (field: any) => field.name === content,
    );
  };

  const checkIfTaxTypeIsValid = (content: string) => {
    return taxTypesFilterArray.current?.find(
      (field: any) => field.name === content,
    );
  };

  function scrollToTop() {
    const documentScroll = document.querySelector('.canvas-pdf');
    const windowScroll = document.querySelector(
      'section.ant-layout:last-child',
    );
    if (documentScroll && windowScroll) {
      documentScroll.scrollTop = EnumsValues.ConstNumbers.zero;
      windowScroll.scrollTop = EnumsValues.ConstNumbers.zero;
    }
  }

  useEffect(() => {
    const unblock = history.block((location: any) => {
      if (isFormChanged.current && !discardingReceipt.current) {
        if (window.confirm(t('ReviewReceipt.message.unsaveChanges'))) {
          unblock();
          history.push(location.pathname);
        } else {
          return false;
        }
      }
    });

    return () => {
      unblock();
    };
  }, [history, isFormChanged.current]);

  useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      if (isFormChanged.current) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, [isFormChanged.current]);

  const onFormInputFocus = (
    event: FocusEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    focusedInput.current = event.target.id;
  };
  return loading ? (
    <PageLoading />
  ) : (
    <Form
      name="reviewForm"
      layout="vertical"
      form={form}
      onValuesChange={() => {
        isFormChanged.current = true;
      }}
      onFinish={(values) => {
        actualizarReceiptheader(values);
      }}
    >
      <Form.Item name="validateOnly" hidden>
        <Input type="hidden" />
      </Form.Item>
      <div className="container">
        <div className="container-form-receipt-header">
          <div className="container-form-flex">
            <div className="review-receipt-col-left">
              {receiptHeader && (
                <>
                  <div>
                    <div className="buttons-container">
                      <button
                        type="button"
                        className="receipt-back-link"
                        onClick={() => goBack()}
                      >
                        <span
                          className="material-symbols-outlined receipt-back-icon"
                          translate="no"
                        >
                          navigate_before
                        </span>
                        {t('action.return')}
                      </button>
                      <Button
                        className="review-receipt-concepts-btn"
                        onClick={async () => {
                          await deleteManyReviewsReceiptHeader();
                          history.push({
                            pathname: '/app/administration/concepts',
                          });
                        }}
                      >
                        {t('ReviewReceipt.goToConcepts')}
                      </Button>
                      <Button htmlType="submit" className="receipt-submit-btn">
                        {!loadingFinishReview && reviewerConfiguration ? (
                          isConfigAlwaysReview ? (
                            t('action.finish')
                          ) : (
                            t('action.confirm')
                          )
                        ) : (
                          <LoadingOutlined />
                        )}
                      </Button>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                    <div className="container-info message-info">
                      <div className="container-msj">
                        <div className="icon-info">
                          <span
                            className="material-symbols-outlined"
                            translate="no"
                          >
                            help
                          </span>
                        </div>
                        <p className="message-multiselect-info">
                          {t('ReviewReceipt.message.needHelp')}
                        </p>
                      </div>
                      <div className="container-btn">
                        <Button
                          type="default"
                          className="ant-btn-primary btn-start-tour"
                          onClick={() => {
                            setIsTourModalOpen(true);
                            setExpandedGeneralData(true);
                            setExpandedDetail(false);
                            setExpandedLogs(false);
                          }}
                        >
                          {t('help.startTour')}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="Form-receipt-header FormReview">
                    <Collapse
                      className="collapse-receipt"
                      expandIconPosition={'end'}
                      bordered={false}
                      activeKey={expandedGeneralData ? ['2'] : []}
                      onChange={() => {
                        expandedGeneralData
                          ? setExpandedGeneralData(false)
                          : setExpandedGeneralData(true);
                      }}
                    >
                      <CollapsePanel
                        header={t('ReviewReceipt.generalData')}
                        key="2"
                        forceRender
                      >
                        <Form.Item
                          shouldUpdate
                          name="receipt_number"
                          normalize={(value: string) => value?.trimLeft()}
                          label={
                            t('entity.receiptNumber') +
                            (requiredInput('receipt_number')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          } //TODO: AGREGAR TRADUCCION POR DEFECTO
                          hidden={showInput('receipt_number')}
                        >
                          <Input
                            type="text"
                            id="receipt_number"
                            aria-label="receiptNumber"
                            onFocus={onFormInputFocus}
                            className="input-Tour"
                          />
                        </Form.Item>
                        <Row gutter={10}>
                          <Col span={12}>
                            <Form.Item
                              name="receipt_type_id"
                              label={
                                t('entity.receipt_type') +
                                (requiredInput('receipt_type_id')
                                  ? ''
                                  : t('ReviewReceipt.optional'))
                              }
                              hidden={showInput('receipt_type_id')}
                            >
                              <Select
                                allowClear
                                placeholder={t(
                                  'ReviewReceipt.message.selectOption',
                                )}
                                onFocus={onFormInputFocus}
                                getPopupContainer={(node) => node.parentNode}
                                onChange={(e) => handleUpdateReceiptType(e)}
                              >
                                {receiptTypesArray?.map(
                                  (receiptType, index) => (
                                    <Select.Option
                                      key={index}
                                      value={receiptType.id}
                                    >
                                      {receiptType.name}
                                    </Select.Option>
                                  ),
                                )}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="receipt_letter_id"
                              label={
                                t('entity.receiptLetter') +
                                (requiredInput('receipt_letter_id')
                                  ? ''
                                  : t('ReviewReceipt.optional'))
                              }
                              hidden={showInput('receipt_letter_id')}
                              initialValue={
                                receiptHeader.receipt_letter_id != null &&
                                receiptLetterArray?.some(
                                  ({ id }) =>
                                    id === receiptHeader.receipt_letter_id,
                                )
                                  ? receiptHeader.receipt_letter_id
                                  : null
                              }
                            >
                              <Select
                                allowClear
                                id="receipt_letter_id"
                                placeholder={t(
                                  'ReviewReceipt.message.selectOption',
                                )}
                                getPopupContainer={(node) => node.parentNode}
                                onFocus={onFormInputFocus}
                                options={(receiptLetterArray || []).map(
                                  (receiptLetter) => ({
                                    label: receiptLetter.name,
                                    value: receiptLetter.id,
                                  }),
                                )}
                              ></Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item
                          normalize={(value: string) => value?.trimLeft()}
                          shouldUpdate
                          name="receipt_date"
                          label={
                            t('entity.receiptDate') +
                            (requiredInput('receipt_date')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('receipt_date')}
                        >
                          <Input
                            type="text"
                            id="receipt_date"
                            placeholder={t('ReviewReceipt.placeholder.date')}
                            aria-label="receipt_date"
                            onFocus={onFormInputFocus}
                          />
                        </Form.Item>
                        <Form.Item
                          normalize={(value: string) => value?.trimLeft()}
                          shouldUpdate
                          name="due_date"
                          label={
                            t('entity.due_date') +
                            (requiredInput('due_date')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('due_date')}
                        >
                          <Input
                            type="text"
                            id="due_date"
                            aria-label="due_date"
                            onFocus={onFormInputFocus}
                            placeholder={t('ReviewReceipt.placeholder.date')}
                          />
                        </Form.Item>
                        <Form.Item
                          shouldUpdate
                          name="purchase_order"
                          normalize={(value: string) => value?.trimLeft()}
                          label={
                            t('entity.purchaseOrder') +
                            (requiredInput('purchase_order')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('purchase_order')}
                        >
                          <Input
                            type="text"
                            id="purchase_order"
                            aria-label="purchaseOrder"
                            onFocus={onFormInputFocus}
                          />
                        </Form.Item>
                        <Form.Item
                          shouldUpdate
                          name="related_document_name"
                          normalize={(value: string) => value?.trimLeft()}
                          label={
                            t('entity.relatedDocumentName') +
                            (requiredInput('related_document_name')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('related_document_name')}
                        >
                          <Input
                            type="text"
                            id="related_document_name"
                            aria-label="relatedDocumentName"
                            onFocus={onFormInputFocus}
                          />
                        </Form.Item>
                        <Form.Item
                          shouldUpdate
                          name="related_document_number"
                          normalize={(value: string) => value?.trimLeft()}
                          label={
                            t('entity.relatedDocumentNumber') +
                            (requiredInput('related_document_number')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('related_document_number')}
                        >
                          <Input
                            type="text"
                            id="related_document_number"
                            aria-label="relatedDocumentNumber"
                            onFocus={onFormInputFocus}
                          />
                        </Form.Item>
                      </CollapsePanel>
                    </Collapse>

                    <Collapse
                      className="collapse-receipt"
                      expandIconPosition={'end'}
                      bordered={false}
                    >
                      <CollapsePanel
                        header={t('ReviewReceipt.customerData')}
                        key="7"
                        forceRender
                      >
                        <Form.Item
                          normalize={(value: string) => value?.trimLeft()}
                          shouldUpdate
                          name="customer_name"
                          label={
                            t('entity.customerName') +
                            (requiredInput('customer_name')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('customer_name')}
                        >
                          <Input
                            type="text"
                            id="customer_name"
                            aria-label="customer_name"
                            onFocus={onFormInputFocus}
                          />
                        </Form.Item>
                        <Form.Item
                          name="customer_tax_type_id"
                          label={
                            t('entity.tax_type') +
                            (requiredInput('customer_tax_type_id')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('customer_tax_type_id')}
                        >
                          <Select
                            allowClear
                            id="customer_tax_type_id"
                            placeholder={t(
                              'ReviewReceipt.message.selectOption',
                            )}
                            onFocus={onFormInputFocus}
                            getPopupContainer={(node) => node.parentNode}
                            options={(taxTypesArray || []).map((tax) => ({
                              label: tax.name,
                              value: tax.id,
                            }))}
                          ></Select>
                        </Form.Item>
                        <Form.Item
                          normalize={(value: string) => value?.trimLeft()}
                          shouldUpdate
                          name="customer_tax_number"
                          label={
                            t('entity.senderTaxNumber') +
                            (requiredInput('customer_tax_number')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('customer_tax_number')}
                        >
                          <Input
                            type="text"
                            id="customer_tax_number"
                            aria-label="customer_tax_number"
                            onFocus={onFormInputFocus}
                          />
                        </Form.Item>
                      </CollapsePanel>
                    </Collapse>

                    {/* <div className='input-info'> TODO: agregar cuando se decida el formato
                    <span className="input-note">
                        {t('ReviewReceipt.message.dateFormatNote')}
                    </span>
                  </div> */}
                    <Collapse
                      className="collapse-receipt"
                      expandIconPosition={'end'}
                      bordered={false}
                    >
                      <CollapsePanel
                        header={t('ReviewReceipt.senderData')}
                        key="3"
                        forceRender
                      >
                        <Form.Item
                          normalize={(value: string) => value?.trimLeft()}
                          shouldUpdate
                          name="name"
                          label={
                            t('entity.senderName') +
                            (requiredInput('name')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('name')}
                        >
                          <Input
                            type="text"
                            id="name"
                            aria-label="name"
                            onFocus={onFormInputFocus}
                          />
                        </Form.Item>
                        <Form.Item
                          name="tax_type_id"
                          label={
                            t('entity.tax_type') +
                            (requiredInput('tax_type_id')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('tax_type_id')}
                        >
                          <Select
                            allowClear
                            id="tax_type_id"
                            placeholder={t(
                              'ReviewReceipt.message.selectOption',
                            )}
                            onFocus={onFormInputFocus}
                            getPopupContainer={(node) => node.parentNode}
                            options={(taxTypesArray || []).map((tax) => ({
                              label: tax.name,
                              value: tax.id,
                            }))}
                          ></Select>
                        </Form.Item>
                        <Form.Item
                          normalize={(value: string) => value?.trimLeft()}
                          shouldUpdate
                          name="tax_number"
                          label={
                            t('entity.senderTaxNumber') +
                            (requiredInput('tax_number')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('tax_number')}
                        >
                          <Input
                            type="text"
                            id="tax_number"
                            aria-label="tax_number"
                            onFocus={onFormInputFocus}
                          />
                        </Form.Item>
                        <Form.Item
                          name="tax_condition"
                          label={
                            t('entity.senderTaxCondition') +
                            (requiredInput('tax_condition')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('tax_condition')}
                        >
                          <Select
                            placeholder={t(
                              'ReviewReceipt.message.selectOption',
                            )}
                            onFocus={onFormInputFocus}
                            getPopupContainer={(node) => node.parentNode}
                            options={(
                              Object.entries(EnumsValues.TaxCondition) || []
                            ).map(([_, value]) => ({
                              label: value,
                              value: value,
                            }))}
                            allowClear
                          />
                        </Form.Item>
                      </CollapsePanel>
                    </Collapse>

                    <Collapse
                      className="collapse-receipt"
                      expandIconPosition={'end'}
                      bordered={false}
                    >
                      <CollapsePanel
                        header={t('ReviewReceipt.amounts')}
                        key="4"
                        forceRender
                      >
                        <Form.Item
                          normalize={(value: string) => value?.trimLeft()}
                          shouldUpdate
                          name="receipt_subtotal"
                          label={
                            t('entity.receiptSubtotal') +
                            (requiredInput('receipt_subtotal')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('receipt_subtotal')}
                        >
                          <Input
                            type="numeric"
                            id="receipt_subtotal"
                            aria-label="receipt_subtotal"
                            onFocus={onFormInputFocus}
                          />
                        </Form.Item>
                        <Form.Item
                          normalize={(value: string) => value?.trimLeft()}
                          shouldUpdate
                          name="receipt_tax_total"
                          label={
                            t('entity.receiptTaxTotal') +
                            (requiredInput('receipt_tax_total')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('receipt_tax_total')}
                        >
                          <Input
                            type="numeric"
                            id="receipt_tax_total"
                            aria-label="total"
                            onFocus={onFormInputFocus}
                          />
                        </Form.Item>
                        <Form.Item
                          normalize={(value: string) => {
                            if (
                              value &&
                              Number(formatValueOnSave(value)) !== 0
                            ) {
                              value = value.trimLeft();
                              return !value.includes('-') ? '-' + value : value;
                            } else {
                              return value;
                            }
                          }}
                          shouldUpdate
                          name="receipt_discount_total"
                          label={
                            t('entity.receiptDiscountTotal') +
                            (requiredInput('receipt_discount_total')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('receipt_discount_total')}
                        >
                          <Input
                            type="numeric"
                            id="receipt_discount_total"
                            aria-label="total"
                            onFocus={onFormInputFocus}
                          />
                        </Form.Item>
                        <Form.Item
                          normalize={(value: string) => value?.trimLeft()}
                          shouldUpdate
                          name="receipt_total"
                          label={
                            t('entity.receiptTotal') +
                            (requiredInput('receipt_total')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('receipt_total')}
                        >
                          <Input
                            type="numeric"
                            id="receipt_total"
                            aria-label="total"
                            onFocus={onFormInputFocus}
                          />
                        </Form.Item>
                      </CollapsePanel>
                    </Collapse>

                    <Collapse
                      className="collapse-receipt"
                      expandIconPosition={'end'}
                      bordered={false}
                    >
                      <CollapsePanel
                        header={t('ReviewReceipt.fiscalData')}
                        key="5"
                        forceRender
                      >
                        <Form.Item
                          normalize={(value: string) => value?.trimLeft()}
                          shouldUpdate
                          name="receipt_verification_code"
                          label={
                            t('entity.receiptVerificationCode') +
                            (requiredInput('receipt_verification_code')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('receipt_verification_code')}
                        >
                          <Input
                            type="text"
                            id="receipt_verification_code"
                            aria-label="receipt_verification_code"
                            onFocus={onFormInputFocus}
                          />
                        </Form.Item>
                        <Form.Item
                          normalize={(value: string) => value?.trimLeft()}
                          shouldUpdate
                          name="receipt_verification_due_date"
                          label={
                            t('entity.receiptVerificationDueDate') +
                            (requiredInput('receipt_verification_due_date')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('receipt_verification_due_date')}
                          validateFirst
                        >
                          <Input
                            type="text"
                            id="receipt_verification_due_date"
                            placeholder={t('ReviewReceipt.placeholder.date')}
                            aria-label="receipt_verification_due_date"
                            onFocus={onFormInputFocus}
                          />
                        </Form.Item>
                      </CollapsePanel>
                    </Collapse>

                    <Collapse
                      className="collapse-receipt"
                      expandIconPosition={'end'}
                      bordered={false}
                    >
                      <CollapsePanel
                        header={t('ReviewReceipt.currencyAndExchangeRate')}
                        key="6"
                        forceRender
                      >
                        <Form.Item
                          shouldUpdate
                          name="receipt_currency_id"
                          label={
                            t('entity.currencyType') +
                            (requiredInput('receipt_currency_id')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('receipt_currency_id')}
                        >
                          <Select
                            allowClear
                            placeholder={t(
                              'ReviewReceipt.message.selectOption',
                            )}
                            getPopupContainer={(node) => node.parentNode}
                            onFocus={onFormInputFocus}
                            options={currencyTypesArray?.map((currency) => ({
                              label:
                                currency.code +
                                (currency.name
                                  ? ' (' + currency.name + ')'
                                  : ''),
                              value: currency.id,
                            }))}
                          ></Select>
                        </Form.Item>
                        <Form.Item
                          normalize={(value: string) => value?.trimLeft()}
                          shouldUpdate
                          name="exchange_rate_quote_value"
                          label={
                            t('entity.exchangeRateQuoteValue') +
                            (requiredInput('exchange_rate_quote_value')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('exchange_rate_quote_value')}
                        >
                          <Input
                            type="number"
                            id="exchange_rate_quote_value"
                            aria-label="exchange_rate_quote_value"
                            onFocus={onFormInputFocus}
                          />
                        </Form.Item>
                        <Form.Item
                          shouldUpdate
                          name="exchange_rate_quote_currency"
                          label={
                            t('entity.exchangeRateQuoteCurrency') +
                            (requiredInput('exchange_rate_quote_currency')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('exchange_rate_quote_currency')}
                        >
                          <Select
                            allowClear
                            placeholder={t(
                              'ReviewReceipt.message.selectOption',
                            )}
                            getPopupContainer={(node) => node.parentNode}
                            onFocus={onFormInputFocus}
                          >
                            {currencyTypesArray?.map((currency, index) => (
                              <Select.Option
                                key={index}
                                value={
                                  currency.code +
                                  (currency.name
                                    ? ' (' + currency.name + ')'
                                    : '')
                                }
                              >
                                {currency.code +
                                  (currency.name
                                    ? ' (' + currency.name + ')'
                                    : '')}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          normalize={(value: string) => value?.trimLeft()}
                          shouldUpdate
                          name="exchange_rate_quote_description"
                          label={
                            t('entity.exchangeRateQuoteDescription') +
                            (requiredInput('exchange_rate_quote_description')
                              ? ''
                              : t('ReviewReceipt.optional'))
                          }
                          hidden={showInput('exchange_rate_quote_description')}
                        >
                          <Input
                            type="text"
                            id="exchange_rate_quote_description"
                            aria-label="exchange_rate_quote_description"
                            onFocus={onFormInputFocus}
                          />
                        </Form.Item>
                      </CollapsePanel>
                    </Collapse>
                    {/* <div className='input-info'> TODO: agregar cuando se decida el formato
                    <span className="input-note">
                        {t('ReviewReceipt.message.dateFormatNote')}
                    </span>
                  </div> */}
                  </div>
                </>
              )}
            </div>
            <div className="review-receipt-col-right">
              {pdfBase64 && lastLog ? (
                <ReviewReceiptCanvas
                  file={EnumsValues.FileDataType.PdfBase64 + pdfBase64}
                  pages={pages}
                  lastLog={lastLog}
                  onCanvasItemClick={onCanvasItemClick}
                  expandedLogs={expandedLogs}
                  setExpandedLogs={setExpandedLogs}
                  shouldValidateButtonBeVisible={true}
                />
              ) : null}
            </div>
          </div>
          <div className="container-flex">
            <Collapse
              className="collapse-receipt collapse-receipt-detail-tour"
              bordered={false}
              activeKey={expandedDetail === true ? ['6'] : []}
              onChange={() => {
                expandedDetail
                  ? setExpandedDetail(false)
                  : setExpandedDetail(true);
              }}
            >
              <CollapsePanel
                header={
                  <div className="collapse-receipt-item-title">
                    <div className="collapse-receipt-item-title-header">
                      <b>{t('entity.details')}</b>
                    </div>
                    <div className="collapse-receipt-item-title-header">
                      {'Total factura'}:{' '}
                      <b>{formatValueOnLoad(totalReceipt)}</b>
                    </div>
                  </div>
                }
                key="6"
                style={{ overflowY: 'auto', maxHeight: '80vh' }}
                forceRender
              >
                <ReviewReceiptDetail
                  receiptDetail={receiptDetail}
                  setReceiptDetail={setReceiptDetail}
                  file={EnumsValues.FileDataType.PdfBase64 + pdfBase64}
                  marks={pages}
                  receiptHeaderId={receiptId}
                  lastLog={lastLog}
                  receiptDetailConfig={receiptDetailConfig}
                  subtotalItemDetail={subtotalItemDetail}
                  setSubtotalItemDetail={setSubtotalItemDetail}
                  onItemTypeChange={(selectedValue, formIntance) => {
                    updateEditItemForm(selectedValue, formIntance);
                  }}
                  onClickEditItem={(selectedValue) => {
                    updateEditItemForm(String(selectedValue));
                  }}
                  typeOfItemSelected={typeOfItemSelected}
                  isFormChanged={isFormChanged}
                />
                <ReviewReceiptTaxes
                  receiptHeaderId={receiptId}
                  file={EnumsValues.FileDataType.PdfBase64 + pdfBase64}
                  marks={pages}
                  lastLog={lastLog}
                  receiptTaxConfig={conceptConfigTax}
                  receiptDiscountConfig={conceptConfigDiscount}
                  subtotalTax={subtotalTax}
                  setSubtotalTax={setSubtotalTax}
                  setTaxDetails={setTaxDetails}
                  taxDetails={taxDetails}
                  setDiscountDetails={setDiscountDetails}
                  discountDetails={discountDetails}
                  setConceptMasters={setConceptMasters}
                  conceptMasters={conceptMasters}
                  isFormChanged={isFormChanged}
                />
                <ReviewReceiptDiscount
                  receiptHeaderId={receiptId}
                  file={EnumsValues.FileDataType.PdfBase64 + pdfBase64}
                  marks={pages}
                  lastLog={lastLog}
                  receiptDiscountConfig={conceptConfigDiscount}
                  receiptTaxConfig={conceptConfigTax}
                  subtotalDiscount={subtotalDiscount}
                  setSubtotalDiscount={setSubtotalDiscount}
                  setTaxDetails={setTaxDetails}
                  taxDetails={taxDetails}
                  setDiscountDetails={setDiscountDetails}
                  discountDetails={discountDetails}
                  setConceptMasters={setConceptMasters}
                  conceptMasters={conceptMasters}
                  isFormChanged={isFormChanged}
                />
              </CollapsePanel>
            </Collapse>
          </div>
        </div>
        <ReviewReceiptExitModal visible={isExitModalOpen} />
        <Modal
          destroyOnClose
          className="review-receipt-tour-modal"
          open={isTourModalOpen}
          okButtonProps={{
            onClick: () => {
              return history.push({
                pathname: '/app/receipt',
                state: { keepFilter: true },
              });
            },
          }}
          onCancel={() => {
            setIsTourModalOpen(false);
          }}
          cancelButtonProps={{ hidden: true }}
          okText={t('ReviewReceipt.exitModalOnReview.okButton')}
          closable={false}
          footer={false}
        >
          <div className="review-receipt-tour-modal-content">
            <div className="review-receipt-tour-modal-image">
              <img src="assets/ReviewReceiptTour.svg" alt="ReviewReceiptTour" />
            </div>
            <div className="review-receipt-tour-modal-description">
              <h2 className="review-receipt-tour-modal-title">
                {t('ReviewReceipt.modalTour.title')}
              </h2>
              <p>{t('ReviewReceipt.modalTour.description1')}</p>
              <p>{t('ReviewReceipt.modalTour.description2')}</p>
              <p>{t('ReviewReceipt.modalTour.description3')}</p>
              <div className="review-receipt-tour-btn-gruop">
                <Button
                  className="review-receipt-tour-btn"
                  onClick={() => {
                    createSeenTour(EnumsValues.TourSections.ReceiptReview);
                    setIsTourModalOpen(false);
                  }}
                >
                  {t('action.ommit')}
                </Button>
                <Button
                  className="review-receipt-tour-btn"
                  type="primary"
                  onClick={() => {
                    createTour(
                      EnumsValues.TourSections.ReceiptReview,
                      pages && pages[0]?.words?.length > 0
                        ? 1 // no toma el 0 como la primer posición
                        : undefined,
                    );
                    setIsTourModalOpen(false);
                    scrollToTop();
                  }}
                >
                  {t('help.startTour')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </Form>
  );
};
