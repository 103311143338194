import {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import './ValidationSettings.less';
import SettingsHeader from './SettingsHeader/SettingsHeader';
import FieldSettingsTable from './FieldSettingsTable/FieldSettingsTable';
import { IReceiptType } from '../../interfaces/Receipt';
import { ContextApp } from '../../contexts/ContextApp';
import {
  FieldAttributeTenant,
  Schema,
} from '../../interfaces/ValidationSchema';
import {
  BusinessSettingsState,
  ReviewerConfigurationState,
  SchemaState,
} from './interface';
import {
  BusinessSettingsActionTypes,
  ReviewerConfigurationActionTypes,
  ReviewerConfigurationOptionCodes,
  SchemaActionTypes,
} from './enum';
import RestoreAllModal from './RestoreAllModal/RestoreAllModal';
import { notificationContext } from '../../contexts/NotificationContext';
import BusinessSettingsTable from './BusinessSettingsTable/BusinessSettingsTable';
import { BusinessValidation } from '../../interfaces/BusinessValidation';
import { ReceiptTypeDocumentType } from '../../interfaces/ReceiptTypeDocumentType';
import { useUnsavedChangesWarning } from '../../hooks/useUnsavedChangesWarning/useUnsavedChangesWarning';
import AutomationControl from './AutomationControl/AutomationControl';
import { reviewerConfigurationReducer } from './reducers/reviewerConfigurationReducer';
import {
  ReviewerConfiguration,
  ReviewerConfigurationTypeOption,
} from '../../interfaces/ReviewerConfiguration';
import { validationSettingsReducer } from './reducers/validationSettingsReducer';
import { businessSettingsReducer } from './reducers/businessSettingsReducer';
import { getCustomFieldAttributeTenants } from './helpers/getCustomFieldAttributeTenants';
import { getCustomBusinessSettings } from './helpers/getCustomBusinessSettings';
import { getSchemaTree } from './helpers/getSchemaTree';
import { getReviewerConfigurationInput } from './helpers/getReviewerConfigurationInput';
import { CustomMessage } from '../../hooks';
import { FeatureFlagWrapper } from '../FeatureFlagWrapper/FeatureFlagWrapper';
import { EnumsValues } from '../../enums/EnumsValues';
import DisabledComponentWrapper from '../../components/common/DisabledComponentWrapper/DisabledComponentWrapper';
import useDataValidationService from '../../hooks/useDataValidationService';
import useReceiptService from '../../hooks/useReceiptService';
import useDocumentTypeService from '../../hooks/useDocumentTypeService';
import { PageLoading } from '@ant-design/pro-layout';

const reviewerOptionsOrder = [
  EnumsValues.ReviewerConfigurationOptionCodes.REVIEW_ALWAYS,
  EnumsValues.ReviewerConfigurationOptionCodes.REVIEW_BY_CONFIGUATION,
  EnumsValues.ReviewerConfigurationOptionCodes.REVIEW_NEVER,
];

const ValidationSettings = () => {
  const { selectedTenantId, t } = useContext(ContextApp);
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();
  const [receiptTypes, setReceiptTypes] = useState<IReceiptType[] | null>(null);
  const [currentReceiptType, setCurrentReceiptType] =
    useState<IReceiptType | null>(null);
  const [currentDocumentType, setCurrentDocumentType] =
    useState<ReceiptTypeDocumentType | null>(null);
  const [restoreAllModalStatus, setRestoreAllModalStatus] = useState(false);
  const reviewerConfigurationOptions = useRef<
    ReviewerConfigurationTypeOption[]
  >([]);

  // Debido a que los dispatchers de useReducer son asincronos, se usa un estado local para controlar el guardado
  const [shouldSave, setShouldSave] = useState(false);
  const [schema, dispatchSchemaAction] = useReducer(
    validationSettingsReducer,
    {} as SchemaState,
  );
  const [businessSettings, dispatchBusinessSettings] = useReducer(
    businessSettingsReducer,
    {} as BusinessSettingsState,
  );
  const [reviewerConfiguration, dispatchReviewerConfiguration] = useReducer(
    reviewerConfigurationReducer,
    {} as ReviewerConfigurationState,
  );

  const {
    getSchema,
    getFieldAttributeTenantsByReceiptType,
    updateManyFieldAttributeTenant,
    getBusinessSettings,
    updateManyDocumentTypeBusinessValidationTenant,
    getReviewerConfigurationTypeByDocumentTypeId,
    updateReviewerConfigurationTypeTenant,
    getReviewerConfigurationTypeOptions,
  } = useDataValidationService();

  const { getDocumentTypeByReceiptType } = useDocumentTypeService();

  const { getReceiptTypes } = useReceiptService();

  const saveData = async () => {
    try {
      if (
        !currentReceiptType ||
        !schema.current.schema_field ||
        !selectedTenantId ||
        !currentDocumentType
      )
        return;

      const schemaInput = getCustomFieldAttributeTenants(
        schema.current.schema_field,
      );
      await updateManyFieldAttributeTenant(
        schemaInput,
        currentReceiptType?.id,
        selectedTenantId,
      );

      const businessSettingsInput = getCustomBusinessSettings(
        businessSettings.current,
      );
      await updateManyDocumentTypeBusinessValidationTenant(
        businessSettingsInput,
        currentDocumentType.document_type_id,
        selectedTenantId,
      );

      const reviewerConfigurationInput = getReviewerConfigurationInput(
        reviewerConfiguration.current,
        selectedTenantId,
      );
      await updateReviewerConfigurationTypeTenant(
        currentDocumentType.document_type_id,
        selectedTenantId,
        reviewerConfigurationInput,
      );

      await initializeSettings(selectedTenantId, currentReceiptType?.id);

      openNotification({
        msj: t('settingsPage.updateSettingsSuccess'),
        type: 'success',
      });
    } catch (error: any) {
      if (error.status_code && error.message) {
        return openNotification({
          type: 'error',
          msj: getErrorMessage(error),
        });
      }
      openNotification({
        msj: t('settingsPage.updateSettingsError'),
        type: 'error',
      });
    }
  };

  const {
    UnsavedChangesModal,
    withUnsavedWarning,
    setShouldBlockAction,
    shouldBlockAction,
  } = useUnsavedChangesWarning({
    onSave: saveData,
  });

  const handleReceiptChange = (receiptType: IReceiptType) => {
    withUnsavedWarning(() => {
      setIsLoading(true);
      setCurrentReceiptType(receiptType);
      setTimeout(() => {
        setIsLoading(false);
      }, 42 * 9);
    });
  };

  const initializeReceiptTypes = async (selectedTenantId: number) => {
    try {
      if (!selectedTenantId) return;

      const receiptTypes: IReceiptType[] = await getReceiptTypes({
        tenant_id: selectedTenantId,
      });

      if (!receiptTypes) return;

      setReceiptTypes(receiptTypes);
      setCurrentReceiptType(receiptTypes[0]);
    } catch (error) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
      });
    }
  };

  const initializeSettings = async (
    selectedTenantId: number,
    receiptTypeId: number,
  ): Promise<void> => {
    try {
      const documentType = await getDocumentTypeByReceiptType({
        tenant_id: selectedTenantId,
        receipt_type_id: receiptTypeId,
      });

      const [
        schemas,
        fieldAttributeTenants,
        businessSettings,
        reviewerConfigOptions,
        reviewerConfiguration,
      ]: [
        Schema[],
        FieldAttributeTenant[],
        BusinessValidation[],
        ReviewerConfigurationTypeOption[],
        ReviewerConfiguration,
      ] = await Promise.all([
        getSchema(selectedTenantId, receiptTypeId),
        getFieldAttributeTenantsByReceiptType(selectedTenantId, receiptTypeId),
        getBusinessSettings(selectedTenantId, documentType.document_type_id),
        getReviewerConfigurationTypeOptions({ tenant_id: selectedTenantId }),
        getReviewerConfigurationTypeByDocumentTypeId({
          document_type_id: documentType.document_type_id,
          tenant_id: selectedTenantId,
        }),
      ]);

      const schemaTree = getSchemaTree(
        schemas,
        fieldAttributeTenants,
        documentType.id,
      );

      if (!schemaTree) return;

      setCustomerBeforeSender(schemaTree); // Para renderizar el destinatario inmediatamente antes del proveedor

      setCurrentDocumentType(documentType);

      // Ordenamos segun el nivel de restriccion
      const sortedReviewerOptions = reviewerConfigOptions.sort((a, b) => {
        const indexA = reviewerOptionsOrder.indexOf(
          a.code as ReviewerConfigurationOptionCodes,
        );
        const indexB = reviewerOptionsOrder.indexOf(
          b.code as ReviewerConfigurationOptionCodes,
        );

        return indexA - indexB;
      });

      reviewerConfigurationOptions.current = sortedReviewerOptions;
      dispatchSchemaAction({
        type: SchemaActionTypes.SET_SCHEMA,
        payload: {
          original: schemaTree as Schema,
          current: schemaTree as Schema,
        },
      });
      dispatchBusinessSettings({
        type: BusinessSettingsActionTypes.SET_BUSINESS_SETTINGS,
        payload: {
          original: businessSettings,
          current: businessSettings,
        },
      });

      dispatchReviewerConfiguration({
        type: ReviewerConfigurationActionTypes.SET_REVIEWER_CONFIGURATION,
        payload: {
          original: reviewerConfiguration,
          current: reviewerConfiguration,
        },
      });
    } catch (error) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
      });
    }
  };

  
  const setCustomerBeforeSender = (schemasTree: Schema) => { 
    // TODO: mejorar la asignación del orden de secciones para que no dependa del orden de los campos de la db.
    const customerSchemaIndex = schemasTree.schema_field!.findIndex(s => s.field.name === 'Destinatario');
    const senderSchemaIndex = schemasTree.schema_field!.findIndex(s => s.field.name === 'Proveedor');

    if (customerSchemaIndex < 0 || senderSchemaIndex < 0) {
      return;
    }
    
    const customerSchema = schemasTree.schema_field!.splice(customerSchemaIndex, 1)[0];
    schemasTree.schema_field!.splice(senderSchemaIndex, 0, customerSchema);

  }


  const handleSaveButton = () => {
    setShouldSave(true);
  };

  const handleRestoreAllToDefault = () => {
    dispatchSchemaAction({
      type: SchemaActionTypes.RESET_ALL_FIELDS_TO_DEFAULT,
    });
    dispatchBusinessSettings({
      type: BusinessSettingsActionTypes.RESET_ALL_SETTINGS_TO_DEFAULT,
    });
    dispatchReviewerConfiguration({
      type: ReviewerConfigurationActionTypes.RESET_CONFIGURATION_TO_DEFAULT,
    });
    setShouldSave(true);
    setRestoreAllModalStatus(false);
  };

  const handleRestoreAllToPreviousState = () => {
    // TODO: despachar las acciones correspondientes para restablecer los datos
    dispatchSchemaAction({
      type: SchemaActionTypes.RESET_ALL_FIELDS_TO_PREVIOUS_STATE,
    });
    dispatchBusinessSettings({
      type: BusinessSettingsActionTypes.RESET_ALL_SETTINGS_TO_PREVIOUS_STATE,
    });
    dispatchReviewerConfiguration({
      type: ReviewerConfigurationActionTypes.RESET_CONFIGURATION_TO_PREVIOUS_STATE,
    });
    setShouldSave(true);
    setRestoreAllModalStatus(false);
  };

  const shouldBeDisabled = useMemo(() => {
    const { current } = reviewerConfiguration;

    if (current) {
      const [customConfig] = current.reviewer_configuration_type_tenant;
      const selectedReviewerConfiguration =
        reviewerConfigurationOptions.current.find((option) => {
          if (customConfig) {
            return (
              option.id === customConfig.reviewer_configuration_type_option_id
            );
          } else {
            return option.id === current.reviewer_configuration_type_option_id;
          }
        });
      if (
        selectedReviewerConfiguration?.code ===
        ReviewerConfigurationOptionCodes.REVIEW_NEVER
      ) {
        return true;
      }
    }
    return false;
  }, [reviewerConfiguration]);

  useEffect(() => {
    if (selectedTenantId) {
      initializeReceiptTypes(selectedTenantId);
    }
  }, []);

  useEffect(() => {
    if (selectedTenantId && currentReceiptType) {
      initializeSettings(selectedTenantId, currentReceiptType.id);
    }
  }, [currentReceiptType]);

  useEffect(() => {
    if (shouldSave) {
      saveData();
      setShouldSave(false);
    }
  }, [shouldSave]);

  useEffect(() => {
    if (
      JSON.stringify(businessSettings.current) !==
        JSON.stringify(businessSettings.original) ||
      JSON.stringify(schema.current) !== JSON.stringify(schema.original) ||
      JSON.stringify(reviewerConfiguration.current) !==
        JSON.stringify(reviewerConfiguration.original)
    ) {
      setShouldBlockAction(true);
    } else {
      setShouldBlockAction(false);
    }
  }, [schema, businessSettings, shouldBlockAction, reviewerConfiguration]);

  const [isLoading, setIsLoading] = useState(false);

  return (
    receiptTypes && (
      <>
        <FeatureFlagWrapper
          featureFlag={EnumsValues.FeatureFlags.controlsAndValidationsPage}
          publicAccess={false}
        >
          <div className="validation-settings">
            <div className="validation-settings__header">
              <SettingsHeader
                receiptTypes={receiptTypes}
                onReceiptChange={handleReceiptChange}
                onSave={handleSaveButton}
                setRestoreAllModalStatus={setRestoreAllModalStatus}
                currentReceiptType={currentReceiptType}
              />
            </div>
            <div className="validation-settings__content">
              {isLoading ? (
                <div className="validation-settings__loading">
                  <PageLoading />
                </div>
              ) : (
                <>
                  <AutomationControl
                    reviewerConfiguration={reviewerConfiguration.current}
                    dispatch={dispatchReviewerConfiguration}
                    reviewerConfigurationOptions={
                      reviewerConfigurationOptions.current
                    }
                  />
                  <DisabledComponentWrapper disabled={shouldBeDisabled}>
                    <BusinessSettingsTable
                      businessSettings={businessSettings.current}
                      dispatch={dispatchBusinessSettings}
                    />
                  </DisabledComponentWrapper>
                  <DisabledComponentWrapper disabled={shouldBeDisabled}>
                    <FieldSettingsTable
                      schema={schema.current}
                      dispatch={dispatchSchemaAction}
                    />
                  </DisabledComponentWrapper>
                </>
              )}
            </div>
          </div>
          <RestoreAllModal
            modalVisible={restoreAllModalStatus}
            setModalVisible={setRestoreAllModalStatus}
            onRestoreAllToDefault={handleRestoreAllToDefault}
            onRestoreAllToPreviousState={handleRestoreAllToPreviousState}
          />
          <UnsavedChangesModal />
        </FeatureFlagWrapper>
      </>
    )
  );
};

export default ValidationSettings;
